<template>
  <div>
    <EmployeeArchivedTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @changePage="handlePageChange"
    ></EmployeeArchivedTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeArchivedTable from "@/modules/school/components/shared/employee/table/EmployeeArchivedTable";

export default {
  name: "StaffArchivedPage",
  components: { EmployeeArchivedTable },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchStaffArchivedList");
    },
    handlePageChange(page) {
      this.$store.commit("setStaffArchivedListPage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffArchivedList",
      pagination: "getStaffArchivedPagination",
      isBusyStore: "getStaffArchivedListBusy",
    }),
  },
};
</script>

<style scoped></style>
