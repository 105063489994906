<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      :responsive="true"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(from)="data">
        {{ changeFormat(data.item.from_date) }}
      </template>

      <template v-slot:cell(to)="data">
        {{ changeFormat(data.item.to_date) }}
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No Employee Archived"
          text-bottom="There is no employee have been archived"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import dayjs from "dayjs";
export default {
  name: "EmployeeArchivedTable",
  components: {
    AppLoader,
    CommonEmployeeSummary,
    AppEmptyList,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        {
          key: "name",
          label: this.$t("EDUGENERAL.ROLE.NAME"),
          sortable: true,
        },
        {
          key: "from",
          label: "Begin",
        },
        {
          key: "to",
          label: "End",
        },
      ],
    };
  },
  methods: {
    changeFormat(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped></style>
